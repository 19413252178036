<template>
  <step-wrapper title="Создание сводного отчета">
    <v-form class="mt-5" :readonly="readonly" v-model="valid">
      <v-row>
        <v-col cols="4">
          <base-date-picker-range v-model="dates" label="Период" />
          <m-autocomplete
            v-model="dataSource.Approvement"
            :items="dataSource.Authors"
            label="Подписывает"
            return-object
            filter="EmployeeActive"
            :dict="{ name: 'Employees', notItems: true }"
            :textCustomAdd="'Создать нового сотрудника'"
          />
        </v-col>
        <v-col cols="8">
          <m-autocomplete
            v-model="dataSource.Authors"
            label="Авторы"
            multiple
            deletable-chips
            required
            :clearable="!readonly"
            :rules="[rules.required]"
            filter="EmployeeActive"
            dict="Employees"
            :textCustomAdd="'Создать нового сотрудника'"
            return-object /></v-col
      ></v-row>
    </v-form>
  </step-wrapper>
</template>
<script>
import { mapActions } from "vuex";
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import stepWrapper from "../stepWrapper.vue";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";
import BaseDatePickerRange from "@/components/base/BaseDatePickerRange.vue";
import mAutocomplete from "@/components/base/inputs/mAutocomplete";
import { defaultSummaryReport } from "@/data/defaultObjects";
import SummaryReportService from "@/services/SummaryReportService";

export default {
  name: "consolidated-report-step1",
  components: { stepWrapper, BaseDatePickerRange, mAutocomplete },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  inject: ["setDefaultData"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiService: SummaryReportService.step1,
      getDefaultObject: defaultSummaryReport.step1(),
      valid: false,
      rules: {
        required: (value) => (!!value && !!value.length) || "Укажите значение.",
      },

      loading: false,
      dataSource: {
        Period: { DateIn: null, DateOut: null },
        Authors: [],
        Approvement: null,
      },
    };
  },
  computed: {
    dates: {
      get() {
        let result = [];
        if (this.dataSource) {
          const start = this.dataSource.Period.DateIn;
          const end = this.dataSource.Period.DateOut;
          result = [start, end];
        }
        return result.filter((e) => e);
      },
      set(value) {
        if (!value || value.length === 0) {
          this.dataSource.Period.DateIn = null;
          this.dataSource.Period.DateOut = null;
        } else if (value?.length === 1) {
          this.dataSource.Period.DateIn = value[0];
          this.dataSource.Period.DateOut = null;
        } else if (value?.length === 2) {
          this.dataSource.Period.DateIn = value[0];
          this.dataSource.Period.DateOut = value[1];
        }
      },
    },
  },
  watch: {
    isUnsaved(val) {
      this.$emit("update:isUnsaved", val);
    },
    loading: {
      immediatle: true,
      handler(val) {
        this.$emit("update:loading", val);
      },
    },
  },
  async mounted() {
    this.LOAD_EMPLOYEES();
    await this.init();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    async init() {
      await this.baseInit();
      this.setDefaultData(this.dataSource);
    },
  },
};
</script>
